exports.components = {
  "component---src-components-blog-details-index-js": () => import("./../../../src/components/Blog/Details/index.js" /* webpackChunkName: "component---src-components-blog-details-index-js" */),
  "component---src-components-careers-details-index-js": () => import("./../../../src/components/Careers/Details/index.js" /* webpackChunkName: "component---src-components-careers-details-index-js" */),
  "component---src-components-case-study-details-index-js": () => import("./../../../src/components/CaseStudy/Details/index.js" /* webpackChunkName: "component---src-components-case-study-details-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-casestudy-js": () => import("./../../../src/pages/casestudy.js" /* webpackChunkName: "component---src-pages-casestudy-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-technologies-chat-bot-js": () => import("./../../../src/pages/technologies/chat-bot.js" /* webpackChunkName: "component---src-pages-technologies-chat-bot-js" */),
  "component---src-pages-technologies-computer-vision-js": () => import("./../../../src/pages/technologies/computer-vision.js" /* webpackChunkName: "component---src-pages-technologies-computer-vision-js" */),
  "component---src-pages-technologies-data-science-js": () => import("./../../../src/pages/technologies/data-science.js" /* webpackChunkName: "component---src-pages-technologies-data-science-js" */),
  "component---src-pages-technologies-deep-learning-js": () => import("./../../../src/pages/technologies/deep-learning.js" /* webpackChunkName: "component---src-pages-technologies-deep-learning-js" */),
  "component---src-pages-technologies-generative-ai-js": () => import("./../../../src/pages/technologies/generative-ai.js" /* webpackChunkName: "component---src-pages-technologies-generative-ai-js" */),
  "component---src-pages-technologies-machine-learning-js": () => import("./../../../src/pages/technologies/machine-learning.js" /* webpackChunkName: "component---src-pages-technologies-machine-learning-js" */),
  "component---src-pages-technologies-mlops-js": () => import("./../../../src/pages/technologies/mlops.js" /* webpackChunkName: "component---src-pages-technologies-mlops-js" */),
  "component---src-pages-technologies-natural-language-processing-js": () => import("./../../../src/pages/technologies/natural-language-processing.js" /* webpackChunkName: "component---src-pages-technologies-natural-language-processing-js" */),
  "component---src-pages-technologies-robotics-js": () => import("./../../../src/pages/technologies/robotics.js" /* webpackChunkName: "component---src-pages-technologies-robotics-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

